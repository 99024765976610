import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class UserAccountController extends Controller {
  @service url;
  @service media;

  get burgerMenuWidth() {
      return this.media.isTablet ? 659 : 350
  }

  get displayLanguageSwitch() {
    return !this.url.isFrenchDomainExtension;
  }
}
