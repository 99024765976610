import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default class UserTutorialsController extends Controller {
  @service intl;
  @service media;

  get burgerMenuWidth() {
    return this.media.isTablet ? 659 : 350
  }
}
